.app-screen-guard {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100svh;
    width: 100svw;
    background-color: rgba(241, 143, 46, .75);
    color: #000;
    padding: 0;
    text-align: center;
    z-index: 9999999;
    position: fixed;
    font-size: 5svw;
    line-height: 7svw;
}

.app-screen-guard p {
    font-weight: 100;
}
