* {
  outline: none!important;
}

.app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
  background-image: url(assets/images/svg/background.svg);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.ligne {
  width: 100vw;
  height: 1px;
  position: fixed;
  top: 50%;
}

.app-logo {
  position: fixed;
  top: 5svh;
  left: 5svw;
}

.app-logo img {
  width: 40svw;
}

.app-logo p {
  font-size: 2.75svw;
  line-height: 2.75svw;
  margin: .5svw 0 0 0;
  font-weight: 100;
}

.app-ground {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100svw;
  height: auto;
}
.app-cloud {
  position: fixed;
  left: 0;
  transform: translate3d(-100%, 0, 0);
}
.app-cloud-0 {
  width: 30vw;
}
.app-cloud-1 {
  width: 20vw;
}
.app-cloud-2 {
  width: 40vw;
}
.app-cloud-3 {
  width: 20vw;
}
.app-cloud-4 {
  width: 30vw;
}
.app-cloud-5 {
  width: 20vw;
}

.app-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
}

.app-btn {
  color: #FFFFFF;
  background-color: rgba(241, 143, 46, .9);
  display: inline-block;
  padding: 1svw 3svw;
  font-size: 2svw;
  line-height: 2svw;
  font-weight: 400;
  border-radius: .5svw;
  text-align: center;
  min-width: 125px;
  border: none;
}

.app-btn-secondary {
  background-color: #A0A2A9;
  border: 1px solid #FFF;
  font-weight: 400;
}

.app-btn-lg {
  font-size: 3.5svw;
  line-height: 3.5svw;
  padding: 2svw 7svw;
}

.app-btn-sm {
  font-size: 1.5svw;
  line-height: 1.5svw;
  padding: .5svw 1.5svw;
}

.app-screen-screensaver .app-btn {
  position: fixed;
  bottom: 15svh;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.app-screen-screensaver p {
  font-size: 1rem;
  position: fixed;
  bottom: .5svh;
  width: 100vw;
  text-align: center;
}

.app-screen-start .app-btn {
  opacity: 0;
  position: fixed;
  bottom: 10svh;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.app-screen-start p {
  opacity: 0;
  font-size: 2.5rem;
  font-weight: 100;
  position: fixed;
  bottom: 25svh;
  width: 100vw;
  text-align: center;
  margin: 0;
}

.app-screen-game {
  background-color: rgba(255, 255, 255, .65);
  opacity: 0;
}

.app-plane {
  position: fixed;
  top: 50svh;
  left: 50svw;
  transform: translate3d(-50%, -50%, 0);
  width: 65svw;
  height: auto;
}

.app-quit, .app-leaderboard-quit {
  position: absolute;
  top: 2svh;
  right: -10svw;
  z-index: 10;
}

.app-quit img, .app-leaderboard-quit img {
  width: 3svw;
  height: auto;
}

.app-quit p, .app-leaderboard-quit p {
  font-size: 1.3svw;
  margin: 0;
  text-align: center;
}

.app-toolbox {
  position: fixed;
  height: 25vh;
  width: 75svw;
  bottom: -100%;
  left: 50svw;
  transform: translate3d(-50%, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  gap: 1svw;
}

.app-toolbox .cargo-item {
  margin-bottom: 1svw;
}
.app-toolbox .cargo-item .cargo-item-wrapper {
  background-color: #FFF;
  border: 1px solid #000;
  border-radius: .5svw;
  height: 7svw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-toolbox .cargo-item.cargo-item-small {
  width: 8vw;
}
.app-toolbox .cargo-item.cargo-item-small img {
  width: 80%;
}
.app-toolbox .cargo-item.cargo-item-medium {
  width: 14vw;
}
.app-toolbox .cargo-item.cargo-item-medium img {
  width: 75%;
}
.app-toolbox .cargo-item.cargo-item-big {
  width: 18vw;
}
.app-toolbox .cargo-item.cargo-item-big img {
  width: 60%;
}
.app-toolbox .cargo-item p {
  font-size: .8svw;
  margin: 0;
  text-align: center;
}

.app-drop-containers {
  position: fixed;
  display: flex;
  gap: 0;
}

.app-drop-containers-displayers {
  position: fixed;
  display: flex;
  gap: 0;
}

.app-drop-containers .drop-container {
  background-color: rgba(0, 0, 0, 0);
  height: 16.5svw;
  display: flex;
  align-items: flex-end;
  flex: 0 0 8.33%;
}

.app-drop-containers-displayers .drop-container {
  background-color: rgba(0, 0, 0, 0);
  height: 9svw;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 0 0 8.33%;
}

.app-drop-containers-displayers .drop-container:last-of-type img {
  clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
}

.app-drop-containers .drop-container:nth-child(1) {
  transform: translate3d(0, 25%, 0);
  z-index: 10;
}

.app-drop-containers .drop-container:nth-child(2) {
  transform: translate3d(0, 20%, 0);
  z-index: 9;
}

.app-drop-containers .drop-container:nth-child(3) {
  transform: translate3d(0, 15%, 0);
  z-index: 8;
}

.app-drop-containers .drop-container:nth-child(4) {
  transform: translate3d(0, 10%, 0);
  z-index: 7;
}

.app-drop-containers .drop-container:nth-child(5) {
  transform: translate3d(0, 5%, 0);
  z-index: 6;
}

.app-drop-containers .drop-container:nth-child(6) {
  transform: translate3d(0, -5%, 0);
  flex: 0 0 16.66%;
  z-index: 5;
}

.app-drop-containers .drop-container:nth-child(7) {
  transform: translate3d(0, -15%, 0);
  flex: 0 0 16.66%;
  z-index: 4;
}

.app-drop-containers .drop-container:nth-child(8) {
  transform: translate3d(0, -20%, 0);
  z-index: 3;
}

.app-drop-containers .drop-container:nth-child(9) {
  transform: translate3d(0, -25%, 0);
  z-index: 2;
  flex: 0 0 13%;
}

.app-drop-containers .drop-container:nth-child(10) {
  transform: translate3d(0, -30%, 0);
  z-index: 1;
}

.app-drop-containers-displayers .drop-container:nth-child(1) {
  transform: translate3d(0, -0.3svw, 0);
  z-index: 10;
}

.app-drop-containers-displayers .drop-container:nth-child(2) {
  transform: translate3d(0, -1svw, 0);
  z-index: 9;
}

.app-drop-containers-displayers .drop-container:nth-child(3) {
  transform: translate3d(0, -2.2svw, 0);
  z-index: 8;
}

.app-drop-containers-displayers .drop-container:nth-child(4) {
  transform: translate3d(0, -3.1svw, 0);
  z-index: 7;
}

.app-drop-containers-displayers .drop-container:nth-child(5) {
  transform: translate3d(0, -4.1svw, 0);
  z-index: 6;
}

.app-drop-containers-displayers .drop-container:nth-child(6) {
  transform: translate3d(0, -4.9svw, 0);
  flex: 0 0 16.66%;
  z-index: 5;
}

.app-drop-containers-displayers .drop-container:nth-child(7) {
  transform: translate3d(0, -7.1svw, 0);
  flex: 0 0 16.66%;
  z-index: 4;
}

.app-drop-containers-displayers .drop-container:nth-child(8) {
  transform: translate3d(0, -9svw, 0);
  z-index: 3;
}

.app-drop-containers-displayers .drop-container:nth-child(9) {
  transform: translate3d(0, -10svw, 0);
  z-index: 2;
  flex: 0 0 13%;
}

.app-drop-containers-displayers .drop-container:nth-child(10) {
  transform: translate3d(0, -11.5svw, 0);
  z-index: 1;
}


.app-drop-containers .drop-container img,
.app-drop-containers-displayers .drop-container img {
  width: 125%;
}


.drag-preview {
  position: fixed;
  pointer-events: none!important;
  transform: rotate(0deg);
  width: 100px;
  z-index: 100000;
  opacity: 1;
  display: block;
}

.drag-preview img {
  width: 100%;
  height: auto;
  opacity: 1;
}

.app-timer {
  position: fixed;
  top: 10svh;
  left: 50svw;
  transform: translate3d(-50%, 0, 0);
  width: 80svw;
}

.app-timer-quick {
  display: flex;
  align-items: center;
  transform: translateY(-1svw);
}

.app-timer-quick img {
  width: 1.5svw;
  margin-left: 5svw;
  margin-right: 1svw;
}

.app-timer-quick p {
  font-size: 2.3svw;
  margin: 0;
  font-weight: 100;
  color: #F18F2E;
}

.app-timer-rest {
  text-align: right;
  font-size: 1.4svw;
  font-weight: 100;
  transform: translateY(1svw);
}

.app-timer-time {
  display: flex;
  align-items: center;
  gap: 1svw;
}

.app-timer-icon {
  display: flex;
}

.app-timer-icon img {
  width: 4svw;
}

.app-timer-full {
  flex: 1;
  background-color: #D9D9D9;
  position: relative;
  width: 100%;
  height: 1.5svw;
  border-radius: 1.5svw;
  overflow: hidden;
}

.app-timer-passed {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0%;
  background-color: #F18F2E;
}

.app-screen-looser {
  display: none;
  opacity: 0;
}

.app-screen-win {
  display: none;
  opacity: 0;
}

.app-bg-orange { background-color: #F18F2E; }
.app-bg-gray { background-color: #A0A2A9; }

.app-modal {
  width: 40svw;
  padding: 2.5svw;
  position: fixed;
  bottom: 10svw;
  left: 50svw;
  transform: translate3d(-50%, 0, 0);
  border-radius: 1svw;
  text-align: center;
  color: #FFFFFF;
}

.app-modal-title {
  font-size: 2svw;
  line-height: 2svw;
  margin: 0 0 .5svw;
  font-weight: 700;
}

.app-modal-description {
  font-size: 1.5svw;
  line-height: 1.5svw;
  margin: 0;
  font-weight: 700;
}

.app-modal-time {
  font-size: 4svw;
  line-height: 4svw;
  margin: 2svw 0;
  font-weight: 700;
}

.app-modal-actions {
  display: flex;
  gap: 1.5svw;
  align-items: center;
  justify-content: center;
}

.app-modal-actions .app-btn {
  width: 50%;
  padding-left: 0;
  padding-right: 0;
}

.app-screen-leaderboard {
  display: none;
  opacity: 0;
}

.app-screen-leaderboard .leaderboard {
  position: fixed;
  left: 5svw;
  right: 5svw;
  bottom: 5svw;
  top: 15svw;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  border-radius: 1svw;
  overflow: hidden;
}

.app-screen-leaderboard .leaderboard .leaderboard-header {
  padding: 2svw 2svw 3svw 2svw;
  background-color: #F18F2E;
  font-weight: 700;
  font-size: 3svw;
  background-image: url(assets/images/delivery-tape.png);
  background-position: bottom left;
  background-size: auto 1svw;
  background-repeat: repeat-x;
}

.app-screen-leaderboard .leaderboard .leaderboard-body {
  background-color: #FFFFFF;
  flex: 1;
  overflow: scroll;
  position: relative;
}

.app-screen-leaderboard .leaderboard .leaderboard-footer {
  height: 1svw;
  background-image: url(assets/images/delivery-tape.png);
  background-position: bottom left;
  background-size: auto 1svw;
  background-repeat: repeat-x;
}

.app-screen-leaderboard .leaderboard .leaderboard-body-score {
  display: flex;
  flex-wrap: wrap;
  font-size: 2svw;
  border-bottom: 1px solid #000;
  padding: 1.5svw 0;
}

.app-screen-leaderboard .leaderboard .leaderboard-body-score:last-of-type {
  border-bottom: none;
}

.app-screen-leaderboard .leaderboard .leaderboard-body-score div {
  display: flex;
  align-items: center;
}

.app-screen-leaderboard .leaderboard .leaderboard-body-score .leaderboard-body-score-index {
  flex: 0 0 25%;
  margin-left: 5svw;
}

.app-screen-leaderboard .leaderboard .leaderboard-body-score .leaderboard-body-score-name {
  flex: 1;
  font-weight: 700;
}

.app-screen-leaderboard .leaderboard .leaderboard-body-score .leaderboard-body-score-score {
  flex: 0 0 10%;
  margin-right: 5svw;
  text-align: right;
}

.app-screen-leaderboard .leaderboard form {
  display: flex;
  align-items: center;
}

.app-screen-leaderboard .leaderboard form input {
  background-color: #FFFFFF;
  border: 2px solid #000;
  height: 3.5svw;
  padding: 0 0 0 2svw;
  width: 25svw;
  margin-right: 2svw;
  font-size: 2svw;
  font-weight: 700;
}

.app-screen-leaderboard .leaderboard form input::placeholder {

}

.app-screen-rules {
  opacity: 0;
  display: none;
}

.app-modal-rules {
  z-index: 100;
  text-align: center;
  padding: 2svw 3svw;
  background-color: rgba(255, 255, 255, .85);
  border: 1px solid #000;
  top: 100svh;
  transform: translate(-50%, -50%);
  bottom: unset;
}

.app-modal-rules p {
  color: #000000;
  font-size: 2.5svw;
  font-weight: 100;
  margin: 0;
}

.app-modal-rules img {
  color: #000000;
  width: 6svw;
  height: auto;
  margin: 1svw 0;
  fill: #000;
}

.app-modal-rules .app-btn {
  padding: 1svw 8svw;
  margin-top: 3svw;
}

.app-hints {
  position: fixed;
  bottom: 1svw;
  left: 1svw;
  right: 1svw;
  height: 3.5svw;
  display: flex;
  gap: 2svw;
  overflow: hidden;
}

.app-hints .app-hints-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  width: 7svw;
}

.app-hints .app-hints-btn img {
  height: 100%;
  width: auto;
  margin-right: 1svw;
}

.app-hints .app-hints-btn p {
  font-size: 1.5svw;
}

.app-hints .app-hints-slider {
  flex: 1;
  background-color: #BB967E;
  display: flex;
  align-items: center;
  position: relative;
  opacity: 0;
  overflow: hidden;
}
.app-hints .swiper-slide {
  display: flex;
}
.app-hints .swiper-wrapper {
  align-items: center;
}
.app-hints .app-hints-slide {
  display: flex;
  align-items: center;
  padding: 0 2svw;
  color: #FFF;
}

.app-hints .app-hints-slide strong {
  margin: 0 2svw 0 0;
  font-size: 1.6svw;
}

.app-hints .app-hints-slide p {
  font-size: 1.25svw;
  margin: 0;
}

.swiper-button-prev { display: none!important; }
.swiper-button-next {
  left: 86svw!important;
}

.swiper-button-next:after {
  color: #FFFFFF;
  font-size: 2svw!important;
}
